import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { MenusServices } from '@app/services/menus.service';
import { NotificationService } from '@app/services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {InsuranceFlexComponent} from './insurance-flex/insurance-flex.component';
import {
  financialSetup,
  insuranceEnum,
  models,
  modelTypeEnum,
} from '../../standard-quote/model/standard-quote.model';
import {StandardQuoteService} from '../standard-quote-service.service';
import {WholeSale} from "../../../../core/model/CoreModel";
import { DealerBranch } from '@app/pages/quote/standard-quote/model/dealer.model';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InsuranceComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InsuranceComponent),
      multi: true,
    },
  ],
})
export class InsuranceComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges {
    @Output() resultCoverage: EventEmitter<any> = new EventEmitter<any>();
    @Output() resetInsurance: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateInsurance: EventEmitter<any> = new EventEmitter<any>();

    @Input() idQuote: any;
    @Input() idDeal: any;
    @Input() financialSetup: financialSetup;
    @Input() financialCondition: any;
    @Input() isValid: boolean;
    @Input() postalCode: string;
    minPassenger: number = 20;
    maxPassenger: number = 50;
    minTrailers: number = 1;
    maxTrailers: number = 2;
    formInsurance: FormGroup | null;
    showInsurance: boolean = false;
    dealerBranch: DealerBranch | undefined;
    insuranceSelected: any;
    insuranceEnum = insuranceEnum;
    isLoading: boolean = false;

    insuranceList: any[] = [
      {description: 'Multianual contado', value: 'COUNTED_MULTIYEAR'},
      {description: 'Multianual financiado', value: 'FINANCED_MULTIYEAR'},
      {description: 'Anual contado', value: 'COUNTED_ANNUAL'},
      {description: 'Anual financiado', value: 'FINANCED_ANNUAL'},
      {description: 'Por cuenta del cliente', value: 'CLIENT'},
    ];

    useList: any[] = [];
    typeList: any[] = [];
    postalCodeList: any[] = [];

    models: models | undefined = this.quoteService.formData?.model;
    modelEnum = modelTypeEnum;

    states: any[];
    _financialSetup: any;
    bodyRequest: any;
    _postalCode: string;
    form: FormGroup;
    isValidInsurance: boolean = false;

    constructor(
      private fb: FormBuilder,
      public dialog: MatDialog,
      route: ActivatedRoute,
      menuService: MenusServices,
      notification: NotificationService,
      private quoteService: StandardQuoteService
    ) {
      super(route, menuService, notification);
      this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['financialSetup']) {
        if (!this._financialSetup) {
          this._financialSetup = this.financialSetup;
          if (
            this._financialSetup &&
            this._financialSetup.financialProduct != null
          )
            this.updateBodyRequest();

          this.getStates();
        } else {
          if (
            this._financialSetup &&
            this._financialSetup.financialProduct !=
            this.financialSetup.financialProduct
          ) {
            this.updateBodyRequest();
            this.getStates();
            this._financialSetup.financialProduct =
              this.financialSetup.financialProduct;
          }
        }
      }
      if (changes['postalCode']) {
        this._postalCode = this.postalCode;
      }
    }

    ngOnInit() {
      this.dealerBranch = this.quoteService.formData?.dealerBranch;
      this.setUseList();
      this.insuranceRefresh();
    }

    setUseList() {
      if (this.models?.modelType == modelTypeEnum.autobuses) {
        this.useList = this.quoteService.useListBuses;
      } else if (
        this.models?.modelType == modelTypeEnum.tractos ||
        this.models?.modelType == modelTypeEnum.camiones
      ) {
        this.useList = this.quoteService.useListTracts;
      }
    }

    setValidators() {
      if (!this.formInsurance) return;
      if (this.models?.modelType == modelTypeEnum.autobuses) {
        this.formInsurance?.controls['quantityPassengers'].setValidators(
          Validators.required
        );
        this.formInsurance?.controls['loadType'].setValue(null);
        this.formInsurance?.controls['quantityTrailes'].setValue(null);
        this.formInsurance?.controls['postalCode'].setValue(null);
      } else if (this.models?.modelType == modelTypeEnum.tractos) {
        this.formInsurance?.controls['loadType'].setValidators(
          Validators.required
        );

        this.formInsurance?.controls['quantityTrailes'].setValue(null);
      } else if (this.models?.modelType == modelTypeEnum.camiones) {
        this.formInsurance?.controls['quantityTrailes'].setValue(
          Validators.required
        );
        this.formInsurance?.controls['quantityPassengers'].setValue(null);
      }
    }

    createForm() {
      this.formInsurance = this.fb.group({
        id: [, []],
        insuranceId: [, []],
        requestId: [, [Validators.required]],
        insuranceName: [, []],
        creationDate: [, []],
        updatedDate: [, []],
        creationUser: [, []],
        updateUser: [, []],
        insuranceType: [, [Validators.required]],
        state: [, [Validators.required]],
        stateName: [, [Validators.required]],
        vehicleServiceType: [, [Validators.required]],
        postalCode: [, []],
        quantityPassengers: [, []],
        loadType: ['A', []],
        quantityTrailes: [1, []],
        packageList: [, [Validators.required]],
      });

      this.formInsurance.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
        if (!this.formInsurance) return;
        this.isValidInsurance =
          this.formInsurance?.controls['insuranceType'].valid &&
          this.formInsurance?.controls['state'].valid &&
          this.formInsurance?.controls['stateName'].valid &&
          this.formInsurance?.controls['vehicleServiceType'].valid &&
          this.valid();
      });

      this.setValidators();
    }

    valid(): boolean {
      if (!this.formInsurance) return false;

      if (this.models?.modelType == modelTypeEnum.autobuses) {
        return this.formInsurance?.controls['quantityPassengers'].valid;
      } else if (
        this.models?.modelType == modelTypeEnum.tractos ||
        this.models?.modelType == modelTypeEnum.camiones
      ) {
        return (
          this.formInsurance?.controls['quantityTrailes'].valid &&
          this.formInsurance?.controls['loadType'].valid
        );
      }
      return false;
    }

    getStates() {
      if (!this.bodyRequest?.financialProduct) return;

      this.isLoading = true;
      this.quoteService.getStatesParametric(this.bodyRequest).subscribe({
        next: (response) => {
          this.states = response.data;
          const state = this.states.find(
            (i) => i.state == this.formInsurance?.controls['stateName'].value
          );
          if (state)
            this.formInsurance?.controls['state'].setValue(state.stateId);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      });
    }

    // getUdisPatametrics() {
    //   if (!this.bodyRequest?.financialProduct) return;
    //   this.isLoading = true;
    //   this.quoteService.getUdisParametric(this.bodyRequest).subscribe({
    //     next: (response) => {
    //       this.useList = response.data;
    //       this.isLoading = false;
    //     },
    //     error: (error) => {
    //       this.isLoading = false;
    //     },
    //   });
    // }

    // getUdis() {
    //   this.quoteService.udiCoverage(this.bodyRequest).subscribe({
    //     next: (response) => {
    //       this.isLoading = false;
    //     },
    //     error: (error) => {
    //       this.isLoading = false;
    //     },
    //   });
    // }

    deleteInsurance() {
      this.isLoading = true;
      const id = this.formInsurance?.controls['id'].value;
      if (id) {
        this.quoteService
          .deleteInsurance(this.idQuote, this.idDeal, id)
          .subscribe({
            next: (response) => {
              this.resetForm();
              this.showNotificationSuccess(
                'Se ha eliminado con éxito el seguro.'
              );
              this.isLoading = false;
            },
            error: (error) => {
              this.isLoading = false;
              this.showNotificationError(
                'Ha ocurrido un error. Vuelva a intentarlo.'
              );
            },
          });
      } else {
        this.resetForm();
        this.isLoading = false;
      }
    }

    resetForm() {
      this.showInsurance = !this.showInsurance;
      this.formInsurance = null;
      this.resetInsurance.emit();
    }

    insuranceRefresh() {
      if (!this.dealerBranch?.dtfsMarshId && !this.dealerBranch?.sofomMarshId) {
        this.deleteInsurance();
        this.showInsurance = false;
        return;
      }
      if (this.formInsurance == null) {
        this.createForm();
      }
      this.setValidators();
    }

    addInsurance() {
      if (!this.dealerBranch?.dtfsMarshId || !this.dealerBranch?.sofomMarshId) {
        this.showNotificationError(
          `La sucursal ${this.dealerBranch?.name ?? ''} no tiene agencia asignada en aseguradora. No es posible cotizar seguro`
      );
      return;
    }
    this.showInsurance = !this.showInsurance;
    if (this.formInsurance == null) {
      this.createForm();
    }
    this.setValidators();
  }

  updateBodyRequest() {
    this.bodyRequest = {
      financialProduct: this._financialSetup?.financialProduct,
      classification: this.models?.classification,
      modelType: this.models?.modelType,
      companyName: this.quoteService.formData?.companyName,
    };
  }

  openInsuranceFlexModal() {
    if (!this.dealerBranch?.dtfsMarshId || !this.dealerBranch?.sofomMarshId) {
        this.showNotificationError(
          `La sucursal ${this.dealerBranch?.wholeSale.key ?? ''} ${this.dealerBranch?.name ?? ''} no tiene agencia asignada en aseguradora. No es posible cotizar seguro`
        );
        return;
      }
      const dialogRef = this.dialog.open(InsuranceFlexComponent, {
        data: {
          financialSetup: this.financialSetup,
          financialCondition: this.financialCondition,
          insurance: this.formInsurance?.getRawValue(),
          models: this.quoteService.formData?.model,
          standardQuoteModel: this.quoteService.formData?.standardQuoteModel,
          dealerBranch: this.dealerBranch,
        },
        maxWidth: '1190px',
      });
      dialogRef.componentInstance.selectedInsuranceSubject.subscribe(
        (selectedValue) => {
          this.insuranceSelected = selectedValue;
          this.insuranceSelected.packageList.coverageListModel =
            this.getListCoverage();
          this.formInsurance?.patchValue(this.insuranceSelected);
          this.formInsurance?.controls['packageList'].setValue(
            this.insuranceSelected.packageList
          );
          this.updateInsurance.emit();
        }
      );
    }

    getListCoverage() {
      let coverages: any[] = [];
      this.insuranceSelected.packageList.coverageListModel.map(
        (coverage: any) => {
          const existingCoverage = coverages.find(
            (c: any) => c.coverageId === coverage.coverageId
          );

          if (!existingCoverage) {
            coverages.push(coverage);
          }
        }
      );
      coverages = coverages.map((i: any) => {
        return {...i, name: this.transformDescription(i.name)};
      });
      return coverages;
    }

    onChangeQuantity() {
      const quantityTrailes =
        this.formInsurance?.controls['quantityTrailes'].value;
      if (quantityTrailes > 2 || quantityTrailes <= 0) {
        this.formInsurance?.controls['quantityTrailes'].setValue(this.minTrailers);
      }
    }

    getZipCode() {
      const stateId = this.formInsurance?.controls['state'].value;
      if (!stateId) return;
      const state = this.states.find((i) => i.stateId == stateId).state;
      this.formInsurance?.controls['stateName'].setValue(state);
      if (this.models?.modelType != modelTypeEnum.autobuses) return;
      this.isLoading = true;
      this.quoteService.getZipCode(state).subscribe({
        next: (response) => {
          this.postalCodeList = response.data.map((i: any) => {
            return {
              label: `${i.postalCode}-${i.settlement}`,
              id: i.id,
              value: i.id,
            };
          });
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      });
    }

    selectedPostalCode(postalCodeId: any) {
      this._postalCode = postalCodeId;
      this.formInsurance?.controls['postalCode'].setValue(postalCodeId);
      this._postalCode = postalCodeId;
    }

    //#region CVA
    writeValue(value: any): void {
      if (value) {
        this.formInsurance?.patchValue(value, {emitEvent: false});
        if (this.formInsurance) {
          this.showInsurance = true;
        }
      }
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    private onChange = (_: any) => {
    };
    private onTouched = () => {
    };

    //#endregion

    //#region Validator
    validate(_control: AbstractControl<any, any>): ValidationErrors | null {
      if (this.formInsurance && this.showInsurance) {
        return this.formInsurance.valid ? null : {invalidFormFinance: true};
      } else {
        return null;
      }
    }

    //#endregion
    calculatePassenger(event: any) {
      const quantityPassengers = this.formInsurance?.controls['quantityPassengers'].value;
      if (quantityPassengers < this.minPassenger) {
        this.formInsurance?.controls['quantityPassengers'].setValue(this.minPassenger);
      }
    }
  }
