<app-img-header title="Cotizador estándar" hasSubTitle="true" subTitle="Nueva cotización" alt="Daimler Truck"
  image="/assets/images/cotizador/intro.jpg">
</app-img-header>

<app-preloader *ngIf="isLoading"></app-preloader>
<app-notifications></app-notifications>
<main class="main" id="standardQuotation">
  <div id="detail" class="white">
    <div class="pageWidth">
      <form [formGroup]="form" (ngSubmit)="update()">
        @if(editOff === true){
          <app-financial-service-view
          ></app-financial-service-view>

          <app-customer-view
          ></app-customer-view>

          <app-models-view
          ></app-models-view>
        }@else {
          <div id="financialService">
            <h3>Servicio financiero</h3>
            <!-- Incluir clase "simple", "conSucursal" o "conDistribuidor" en el div.wrapper según corresponda -->
            <div class="wrapper conDistribuidor">
              <div class="selectWrapper">
                <label for="">Compañia <span class="required">*</span></label>
                <div class="customSelect">
                  <select
                    type="text"
                    formControlName="companyName"
                    (change)="onInputFocus()"
                  >
                    <option [value]="'SOFOM'">SOFOM</option>
                    <option [value]="'DTFS'">DTFS</option>
                  </select>
                </div>
              </div>

              <!-- Mostrar solo si el usuario puede seleccionar Distribuidor -->
              <div class="selectWrapper" *ngIf="user === 'INTERNAL' && dealers.length > 1">
                <label for="">Distribuidor <span class="required">*</span></label>
                <div class="customSelect">
                  <select formControlName="dealerId" [disabled]="true" (ngModelChange)="onChangeDealer()">
                    <option *ngFor="let dealer of dealers" [ngValue]="dealer.id">
                      {{ dealer.name }}
                    </option>
                  </select>
                </div>
              </div>
              @if(dealerBranches.length === 0){
                <div class="selectWrapper">
                  <label>Sucursal</label>
                    <div class="customSelect">
                      <select [disabled]="true">
                        <option [ngValue]= "null">
                          {{'El distribuidor no tiene sucursales habilitadas.'}}
                        </option>
                      </select>
                    </div>
                </div>
              }@else{
                <!-- Mostrar solo si el usuario puede seleccionar Sucursal -->
              <div class="selectWrapper" *ngIf="dealerBranches.length >= 1">
                <label for="">Sucursal <span class="required">*</span></label>
                <div class="customSelect">
                  <select formControlName="wholeSaleId" [disabled]="dealerBranches.length === 1">
                    <option *ngFor="let dealerBranch of dealerBranches" [ngValue]="dealerBranch.id">
                      {{ dealerBranch.name }}
                    </option>
                  </select>
                </div>
              </div>
              }
              <div class="inputWrapper">
                <label for=""
                  >Nombre de la cotización <span class="required">*</span></label
                >
                <input name="" id="quoteName" formControlName="quoteName" />
              </div>
              <div class="inputWrapper" *ngIf="idQuote">
                <label for="">Número</label>
                <input
                  type="number"
                  [value]="idQuote"
                  disabled
                  (requestOpenModal)="openRequestedModal()"
                />
              </div>
            </div>
          </div>
          <app-customer
          formControlName="customer"
          [creationUser]="creationUser"
          (requestOpenModal)="openRequestedModal()"
        ></app-customer>
        <app-models
          [ready]="ready"
          (updateForm)="updateForm($event)"
          (setQuantity)="setQuantity($event)"
          (deleteAllDeals)="deleteAllDeals()"
        ></app-models>
        }
        <!-- <app-accessories
          [formArray]="form.get('accessories')"
          [accessories]="this.accessories"
          [creationUser]="creationUser"
          [idQuote]="idQuote"
        ></app-accessories> -->
        <app-deals-standard-quote
          (dealsUpdate)="updateDeal($event)"
          (setValueInService)="setValueInService(); isSetNullData = false"
          [deals]="form.controls['standardQuoteDeals'].value"
          [idQuote]="idQuote"
          [isValidForm]="isValidForm"
          [creationUser]="creationUser"
          [status]="form.controls['status'].value"
          [model]="form.controls['model'].value"
        />
        <div class="quotationActions">

          @if(editOff === true){
            <button type="button" (click)="cancel()" class="secondary">
              Volver
            </button>
            <button
              type="button"
              class="secondary"
              (click)="downloadPdf()"
              [disabled]="deals.length == 0 || !isSelectedDeal"
            >
              Descargar PDF
            </button>
          }@else {
            <button type="button" (click)="cancel()" class="secondary">
              {{
                creationUser == null || creationUser == authService.currentUserIds
                  ? "Cancelar"
                  : "Volver"
              }}
            </button>
            <button
              type="button"
              class="secondary"
              (click)="update(true)"
              [disabled]="deals.length == 0 || !isSelectedDeal"
            >
              Descargar PDF
            </button>
            <button
              type="submit"
              class="primary"
              [disabled]="!saved"
              *ngIf="
                creationUser == null || creationUser == authService.currentUserIds
              "
            >
              Guardar
            </button>
          }
        </div>
      </form>
    </div>
  </div>

</main>
<ng-template #modalTemplate>
  <div class="modal" style="max-width: 620px">
    <a class="close" (click)="cancelChanges()">Cerrar</a>
    <h1>Eliminar propuestas</h1>
    <p>
      Está editando datos sensibles, si continúa con los cambios todas las
      propuestas generadas anteriormente serán eliminadas.
    </p>
    <div class="modalActions index">
      <button (click)="cancelChanges()" class="secondary">Cancelar</button>
      <button (click)="deleteAllDeals()" class="primary">Eliminar</button>
    </div>
  </div>
</ng-template>
