import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import {
  modelTypeEnum,
  models,
  priceTypeEnum,
} from '../../model/standard-quote.model';
import { StandardQuoteService } from '../../standard-quote-service.service';
import { FileType, SubBrandService } from '@app/services/subbrand.service';

@Component({
  selector: 'app-models-view',
  templateUrl: './models-view.component.html',
  styleUrls: ['./models-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsViewComponent implements OnInit {
  private quoteService = inject(StandardQuoteService);
  private subBrandService = inject(SubBrandService);
  subBrandImg = signal('');
  model: models | undefined = this.quoteService.formData?.model;

  ngOnInit() {
    if (this.model) {
      this.getFile(this.model?.subBrand?.id || 0);
    }
  }

  getFile(id: number) {
    if (id === 0) return;
    this.subBrandService
      .getAttachmentByElementId(id, FileType.SUB_BRAND)
      .subscribe({
        next: (response: any) => {
          if (response.data.length > 0) {
            this.subBrandImg.set(response.data[0].urlSource || '');
          } else {
            this.subBrandImg.set('');
          }
        },
        error: (error) => {
          this.subBrandImg.set('');
          if (error.status === 404) {
            return;
          }
        },
      });
  }

  getClassification(classification: boolean) {
    if (classification) {
      return 'Nuevo';
    } else if (!classification) {
      return 'Usado';
    } else {
      return '';
    }
  }

  getModel(modelType: modelTypeEnum) {
    if (modelType == modelTypeEnum.accesorios) {
      return 'Accesorios';
    } else if (modelType == modelTypeEnum.autobuses) {
      return 'Autobuses';
    } else if (modelType == modelTypeEnum.camiones) {
      return 'Camiones';
    } else if (modelType == modelTypeEnum.tractos) {
      return 'Tractos';
    }
    return '';
  }

  getPriceType(priceType: priceTypeEnum) {
    return 'Precio valor comercial';
  }
}
