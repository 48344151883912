import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.base.service';
import { environment } from '@env/environment.local';

export enum FileType {
  MAIL_SHOT = 'MAIL_SHOT',
  SUB_BRAND = 'SUB_BRAND',
  NOTIFICATIONS = 'NOTIFICATIONS',
}

@Injectable({
  providedIn: 'root',
})
export class SubBrandService {
  constructor(public api: ApiService, public httpClient: HttpClient) {}

  getAttachmentByElementId(id: number, type: FileType) {
    const params = new HttpParams().set('typeFile', type);
    return this.api.getWithOptions(
      `${environment.API_FILE_SERVICE}/file/elements/${id}`,
      { params }
    );
  }
}
