<div class="module client">
  <div class="title">
    <h3>Cliente</h3>
    <!-- Mostrar a.viewQuote solamente cuando se haya generado la cotización-->
    <!-- <a class="viewQuote" (click)="redirection.emit()">Ver cotización</a> -->
  </div>

  <form action="" [formGroup]="formCustomer">
    <div class="wrapperG5">
      <div class="selectWrapper">
        <label for="">Tipo de persona</label>
        <div class="customSelect">
          <select
            type="text"
            formControlName="personType"
            (change)="onInputFocus()"
          >
            <option [value]="'NATURAL_PERSON'">Persona física</option>
            <option [value]="'LEGAL_PERSON'">Persona moral</option>
          </select>
        </div>
      </div>
      <div class="inputWrapper">
        <label for=""
          >{{ "Name" | translate }}<span class="required">*</span></label
        >
        <div class="aux">
          <input type="text" formControlName="firstName" (blur)="reload()" />
          <div
            *ngIf="
              formCustomer.get('firstName')?.errors &&
              formCustomer.get('firstName')?.dirty
            "
          >
            <span
              class="alert"
              *ngIf="formCustomer.get('firstName')?.hasError('required')"
              >{{ "Name required" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="inputWrapper"
        *ngIf="
          this.formCustomer.controls['personType'].value == 'NATURAL_PERSON'
        "
      >
        <label for="">Segundo nombre</label>
        <input type="text" formControlName="secondName" />
        <div
          *ngIf="
            formCustomer.get('secondName')?.errors &&
            formCustomer.get('firstName')?.dirty
          "
        ></div>
      </div>
      <div
        class="inputWrapper"
        *ngIf="
          this.formCustomer.controls['personType'].value == 'NATURAL_PERSON'
        "
      >
        <label for=""
          >{{ "LastName" | translate }}<span class="required">*</span></label
        >
        <div class="aux">
          <input type="text" formControlName="lastName" />
          <div
            *ngIf="
              formCustomer.get('lastName')?.errors &&
              formCustomer.get('lastName')?.dirty
            "
          >
            <span
              class="alert"
              *ngIf="formCustomer.get('lastName')?.hasError('required')"
              >{{ "LastName required" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="inputWrapper"
        *ngIf="
          this.formCustomer.controls['personType'].value == 'NATURAL_PERSON'
        "
      >
        <label for=""
          >{{ "MothersLastName" | translate
          }}<span class="required">*</span></label
        >
        <div class="aux">
          <input type="text" formControlName="mothersLastName" />
          <div
            *ngIf="
              formCustomer.get('mothersLastName')?.errors &&
              formCustomer.get('mothersLastName')?.dirty
            "
          >
            <span
              class="alert"
              *ngIf="formCustomer.get('mothersLastName')?.hasError('required')"
              >{{ "MothersLastName required" | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
