<div class="columnView">
  <!-- A continuación de div.fiex hay que incluir div.cell con valores de cada propuesta-->
  <div class="header">
    <div class="fixed">Concepto</div>
    <div class="cell" *ngFor="let deal of deals; let i = index">
      <div class="actionsAndTitle">
        <!-- Esta se itera por cada una -->
        <a class="dragControl" helipopper="Arrastrar para cambiar el orden"
          >Arrastrar para cambiar el orden</a
        >
        @if (status !== 'SAVED') {
          <div
          class="actions"
          *ngIf="
            creationUser == null || creationUser == authService.currentUserIds
          "
        >
          <a
            class="edit"
            tp="Editar propuesta"
            (click)="edit.emit(deal.id)"
            >Editar</a
          >
          <a
            class="duplicate"
            tp="Duplicar propuesta"

            (click)="duplicate.emit(deal.id)"
            >Duplicar</a
          >
          <a
            class="delete"
            tp="Eliminar propuesta"

            (click)="delete.emit(deal.id)"
            >Eliminar</a
          >
        </div>
        }

        <div class="title">
          <label class="checkboxWrapper">
            <input type="checkbox"
            [id]="i + 'deal'"
            [checked]="deal.isSelected"
            [disabled]=" !deal.payments || !deal.payments.length ||creationUser != authService.currentUserIds || status === 'SAVED'"
            (click)="toggleSelection($event, i,deal.id)" />
                        <span class="checkmark"></span>
            <span class="description"
              >{{ getFinancialProduct(deal.financialSetup.financialProduct) }}
              {{ deal.financialCondition.term + " meses" }}</span
            >
          </label>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="fixed">Producto Financiero</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ getFinancialProduct(deal.financialSetup.financialProduct) }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Plazo</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal.financialCondition.term + " meses" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Tasa al cliente</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ (deal.financialCondition.nominalRate).toFixed(2) + " %" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Enganche</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.downpaymentAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Comisión por apertura</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.openingfeeAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Depóstio en garantía</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.guaranteeDepositAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Seguro del vehículo</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ showInsuranceVehicle(deal) | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Seguro de vida</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.insuranceAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Gastos de ratificación</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.confirmationExpensesAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Pago inicial</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.initialPayment | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Monto a financiar</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.financialAmount | currency : "" : "" }}
      </div>
    </div>

    <div class="row">
      <div class="fixed">Pago mensual</div>
      <div class="cell" *ngFor="let deal of deals; let i = index">
        {{ deal?.result?.monthlyPayment | currency : "" : "" }}
      </div>
    </div>
  </div>
</div>
