import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/components/base/base.component';
import { StandardQuoteService } from '../standard-quote-service.service';
import { AuthService } from '@app/common/auth.service';
import { insurance, models } from '../model/standard-quote.model';
import { ModalService } from '@app/components/modal/modal.service';
import { WholeSale } from '@app/core/model/CoreModel';

@Component({
  selector: 'app-deals-standard-quote',
  templateUrl: './deals-standard-quote.component.html',
  styleUrls: ['./deals-standard-quote.component.css'],
})
export class DealsStandardQuoteComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  isLoading: boolean = false;
  showRow: number = 1;
  dealName: string = '';
  title: string = 'Eliminar propuesta';
  subtitle: string = '';
  btn1: string = 'Cancelar';
  btn2: string = 'Eliminar';
  idDeal: number;
  currentPageIndex: number = 0;
  pageSize: number = 8;
  pageIndex: number = 0;

  @Output() setValueInService: EventEmitter<any> = new EventEmitter();
  @Output() dealsUpdate: EventEmitter<any> = new EventEmitter();
  @Input() status: any;

  @Input() deals: any[] = [];
  @Input() idQuote: any;
  @Input() isValidForm: boolean;
  @Input() creationUser: any;
  @Input() model: models;
  wholeSale: WholeSale;

  constructor(
    private router: Router,
    private quoteService: StandardQuoteService,
    public authService: AuthService,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit() {
  }

  addNewDeal() {
    this.setValueInService.emit();
    if (this.idQuote) {
      this.dealsUpdate.emit(this.deals);
      this.router.navigate([
        `standard-quote/edit-quote/${this.idQuote}/new-deal`,
      ]);
    } else {
      this.router.navigate([`standard-quote/new-quote/new-deal`]);
    }
  }

  editDeal(index: number) {
    // this.setValueInService.emit();
    if (this.idQuote) {
      this.dealsUpdate.emit(this.deals);
      this.router.navigate([
        `standard-quote/edit-quote/${this.idQuote}/edit-deal/${index}`,
      ]);
    } else {
      this.router.navigate([`standard-quote/new-quote/edit-deal/${index}`]);
    }
  }

  deleteDeal() {
    this.closeModal();
    this.isLoading = true;
    this.quoteService.deleteDeal(this.idQuote, this.idDeal).subscribe({
      next: (response) => {
        this.deals = this.deals.filter((i) => i.id != this.idDeal);
        this.dealsUpdate.emit(this.deals);
        this.isLoading = false;
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de eliminar propuesta'
        );
        this.isLoading = false;
      },
    });
  }

  openModalConfirmation(idDeal: number) {
    this.idDeal = idDeal;
    let deal = this.deals.find((i) => i.id == idDeal);
    this.dealName = `${this.quoteService.getFinancialProduct(
      deal.financialSetup.financialProduct
    )} ${deal.financialCondition.term} meses`;
    this.subtitle = `¿Confirma la eliminación de la propuesta "${this.dealName}"?`;
    this.modalService.component = this.modalTemplate;
    this.modalService.openModal();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  duplicateDeal(idDeal: any) {
    const newDeal = this.deals.find((i) => i.id == idDeal);
    const coverageListModel = newDeal.insurance ? newDeal.insurance.packageList ?
      newDeal.insurance.packageList.coverageListModel
        .map((coverage: any) => {
          return { ...coverage, id: null, creationDate: null, updatedDate: null }
        }) : null : null;
    const packageList = newDeal.insurance ? newDeal.insurance.packageList : null;
    const dealDuplicate = {
      ...newDeal,
      id: null,
      creationDate: null,
      updatedDate: null,
      isSelected: false,
      financialSetup: {
        ...newDeal.financialSetup,
        creationDate: null,
        updatedDate: null,
      },
      financialCondition: {
        ...newDeal.financialCondition,
        creationDate: null,
        updatedDate: null,
      },
      standardFinancialSetup: {
        id: null,
        creationDate: null,
        updatedDate: null,
        ...newDeal.standardFinancialSetup,
      },
      insuranceDeals: [],
      result: {
        ...newDeal.result,
        id: null,
        creationDate: null,
        updatedDate: null,
      },
      insurance: this.duplicateInsurance(newDeal.insurance),
      payments: this.getPayments(newDeal.payments),
      grace: this.getGrace(newDeal.grace),
      specialPayments: this.getSpecialPayments(newDeal.specialPayments),
    };
    this.quoteService.duplicateDeal = dealDuplicate;
    this.addNewDeal();
  }


  duplicateInsurance(insurance: insurance) {
    if (!insurance) return null
    const coverageListModel = insurance ? insurance.packageList ?
      insurance.packageList.coverageListModel
        .map((coverage: any) => {
          return { ...coverage, id: null, creationDate: null, updatedDate: null }
        }) : null : null;
    const packageList = insurance ? insurance.packageList : null;
    return {
      ...insurance,
      id: null,
      packageList: {
        ...packageList,
        id: null,
        creationDate: null,
        updatedDate: null,
        coverageListModel: coverageListModel
      }
    };
  }

  onChangeSelected(index: number) {
  }

  changeDesign(selected: number) {
    this.showRow = selected;
  }

  getPayments(payments: any) {
    if (!payments) return null
    return payments.map((payment: any) => {
      return { ...payment, id: null, creationDate: null, updatedDate: null };
    });
  }

  getGrace(grace: any) {
    if (!grace) return null
    return grace.map((grace: any) => {
      return { ...grace, id: null, creationDate: null, updatedDate: null };
    });
  }

  getSpecialPayments(specialPayments: any) {
    if (!specialPayments) return null
    return specialPayments.map((specialPayment: any) => {
      return { ...specialPayment, id: null, creationDate: null, updatedDate: null };
    });
  }

  isSelected(id: number) {
    const deals = this.deals.map((i) => {return {...i, isSelected: i.id == id ? !i.isSelected : i.isSelected}})
    this.dealsUpdate.emit(deals);
  }

  get paginatedDeals(): any[] {
    const startIndex = this.pageIndex * this.pageSize;
    return this.deals.slice(startIndex, startIndex + this.pageSize);
  }

  // Evento cuando se cambia de página
  onPageChange(event: { pageIndex: number, pageSize: number }) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }
}
