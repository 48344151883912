import { Component, EventEmitter, model, OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenusServices } from '@app/services/menus.service';
import { BaseComponent } from '@app/components/base/base.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/services/notifications.service';
import { RolesInternal, UserType } from '@app/models/roles.enum';
import { StandardQuoteService } from './standard-quote-service.service';
import { ModalService } from '@app/components/modal/modal.service';
import { AuthService } from '@app/common/auth.service';
import { Subject } from 'rxjs';
import { Dealer, DealerBranch } from '@app/pages/quote/standard-quote/model/dealer.model';
import { statusType } from '../open-quote/model/open-quote.model';

const dealerBranchControlName = 'dealerBranch';
const dealerBranchIdControlName = 'wholeSaleId';
const dealerControlName = 'dealer';
const dealerIdControlName = 'dealerId';

@Component({
  selector: 'app-new-quote',
  templateUrl: './new-standard-quote.component.html',
  styleUrls: ['./new-standard-quote.component.css'],
})
export class NewStandardQuoteComponent extends BaseComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  @Output() requestOpenModal: EventEmitter<void> = new EventEmitter<void>();

  statusAfterToDownloadPdf = '';
  form: FormGroup;
  isLoading: boolean = false;
  role: RolesInternal;
  module: string = '';
  idQuote: any;
  quote = [];
  dealers: Dealer[] = [];
  accessories: any[] = [];
  isSetNullData: boolean = true;
  isValidForm: boolean = false;
  creationUser: string;
  isSelectedDeal: boolean = false;
  deals: any[] = [];
  id: any = 0;
  _quote: any;
  dealerBranches: DealerBranch[] = [];
  user: UserType;
  ready: Subject<any> = new Subject<any>();
  editOff: boolean = false;
  isSaved: string;
  saved: boolean;
  dealerId: number;

  constructor(
    route: ActivatedRoute,
    menu: MenusServices,
    notification: NotificationService,
    public quoteService: StandardQuoteService,
    private fb: FormBuilder,
    private router: Router,
    private routee: ActivatedRoute,
    private modalService: ModalService,
    public authService: AuthService,
  ) {
    super(route, menu, notification);
  }

  ngOnInit() {
    this.user = this.authService.getCurrentUserType[0];
    this.filterDealers()
    this.dealerId = this.authService.getCurrentDealerId
    this.module = this.sendDataToMenu();
    this.createForm();
    this.routee.paramMap.subscribe((params) => {
      this.idQuote = params.get('id');
    });
    if (this.idQuote) {
      this.getQuoteById();
    } else {
      if (this.quoteService.formData) {
        this.form.patchValue(this.quoteService.formData);
      }
      this.getModuleAndRole();
    }

    this.form.controls[dealerBranchControlName].valueChanges.subscribe(value => {
      if (value) {
        this.form.controls[dealerBranchIdControlName].setValue(this.quoteService.formData?.wholeSaleId);
      }
    });
  }

  filterDealers() {
    this.isLoading = true
    this.quoteService.getDealersInStandarQuote().subscribe((response) => {
        this.dealers = response.data.filter((dealer: { dealerBranches: any[]; name: string; enabled: boolean; }) => {
          const hasMarshId = dealer.dealerBranches?.some(
            branch => branch.dtfsMarshId || branch.sofomMarshId
          );
          return dealer.enabled === true && hasMarshId;
        });
        const dealerExists = this.dealers.some(dealer => dealer.id === this.dealerId);
        if (!dealerExists) {
            alert("Su distribuidor se encuentra deshabilitado.");
            this.router.navigate(['/quote/standard-quote']);
        }
        this.isLoading = false
    });
  }

  getModuleAndRole() {
    if (UserType.internal === this.authService.getCurrentUserType[0]) {
      const dealers = this.authService.getCurrentDealers;
      const defaultDealerId = this.dealerId || this.quoteService.formData?.dealerId || 24;
      let selectedDealer = dealers.find((d: any) => d.id === defaultDealerId);
  
      if (selectedDealer) {
        this.form.controls[dealerControlName].setValue(selectedDealer);
        this.form.controls[dealerIdControlName].setValue(selectedDealer.id);
  
        const availableBranches = selectedDealer.dealerBranches?.filter(
          (branch: DealerBranch) => (branch.dtfsMarshId || branch.sofomMarshId) && branch.enabled
        ) || [];
        this.dealerBranches = availableBranches;
        const defaultBranchId = this.quoteService.formData?.wholeSaleId || availableBranches[0]?.id;
        const selectedBranch = availableBranches.find((b: any) => b.id === defaultBranchId);
        if (selectedBranch) {
          this.form.controls[dealerBranchControlName].setValue(selectedBranch);
          this.form.controls[dealerBranchIdControlName].setValue(selectedBranch.id);
        }else {
          this.form.controls[dealerBranchControlName].setValue(availableBranches[0]);
          this.form.controls[dealerBranchIdControlName].setValue(availableBranches[0].id);
        }
      }
    } else {
      this.getRelationalName();
      const dealer = this.authService.getCurrentDealers.find(
        (d: any) => d.id === this.authService.getCurrentDealerId
      ) || { id: 0 };
  
      this.form.controls[dealerControlName].setValue(dealer);
      this.form.controls[dealerIdControlName].setValue(dealer.id);
    }
  }
  
getQuoteById() {
  this.isLoading = true;
  this.quoteService.getById(this.idQuote).subscribe({
    next: (response) => {
      this.creationUser = response.data.creationUser;
      const quoteDealerId = response.data.dealerId ?? 'unknown';
      if (this.creationUser === this.authService.currentUserIds || quoteDealerId === this.dealerId) {
          response.data.model.priceType = response.data.standardQuoteModel.priceType;
      this.form.patchValue(response.data);
      this.setAccessoriesValues(response.data.accessories);
      let price = response.data.standardQuoteModel.price;
          let model = response.data.model;
      model.price = price;
      this.form.controls['model'].setValue(model);
      this.form.controls['quantity'].setValue(response.data.quantity);
      this.ready.next({
        ...response.data.model,
        quantity: response.data.quantity,
        creationUser: response.data.creationUser,
        hasDeals: response.data.standardQuoteDeals.length > 0,
      });
      this.quoteService.formData = response.data;
      this.isSaved = response.data.status
      setTimeout(() => {
        this.isLoading = false;
      }, 3200);

          if (this.creationUser != this.authService.currentUserIds) {
              this.form.controls['companyName'].disable();
              this.form.controls['quoteName'].disable();
              this.form.controls['dealerId'].disable();
              this.form.controls['wholeSaleId'].disable();
              this.editOff = true;
          }
          this.getModuleAndRole();
      } else {
          alert("No tienes permiso para acceder a esta cotización. Acceso Denegado");
          this.router.navigate(['/quote/standard-quote']);
          this.isLoading = false;
      }
    },
    complete: () => {
      //this.getModels();
      if (this.creationUser != this.authService.currentUserIds || this.isSaved === 'SAVED') {
        this.form.controls['companyName'].disable();
        this.form.controls['quoteName'].disable();
        this.form.controls['dealerId'].disable();
        this.form.controls['wholeSaleId'].disable();
        this.form.controls['status'].disable();
        this.editOff = true;
      }
      this.getModuleAndRole();
      setTimeout(() => {
        this.isLoading = false;
      }, 3200);
    },
    error: (error) => {
      this.isLoading = false;
    },
  });
}


  models: any[] = [];

  createForm() {
    this.form = this.fb.group({
      companyName: [, [Validators.required]],
      quoteName: [, [Validators.required]],
      dealer: [, []],
      dealerId: [, []],
      id: [, []],
      customer: [, [Validators.required]],
      model: [, [Validators.required]],
      standardQuoteDeals: [[], Validators.required],
      accessories: this.fb.array([]),
      creationDate: [, []],
      updatedDate: [, []],
      creationUser: [, []],
      status: [, []],
      dealAmount: [, []],
      quantity: [, [Validators.required]],
      dealerBranch: [, []],
      wholeSaleId: [, []]
    });

    // let initializing = true;

    this.form.valueChanges.subscribe((value) => {
      this.deals = this.form.controls['standardQuoteDeals'].value;
      this.isValidForm =
        this.form.controls['model'].valid &&
        this.form.controls['customer'].valid &&
        this.form.controls['quantity'].valid &&
        this.form.controls['companyName'].valid &&
        this.form.controls['quoteName'].valid &&
        this.form.controls['dealer'].valid &&
        this.form.controls['wholeSaleId'].valid;
        //para habilitar y deshabilitar el boton de guardado
        this.saved =
        this.isValidForm &&
        this.form.controls['standardQuoteDeals'].valid

      if (this.deals && this.deals.length > 0)
        this.isSelectedDeal = this.deals.some(
          (deal) => deal.isSelected == true
        );
    });
  }

  getRelationalName() {
    const dealerId = this.authService.getCurrentDealerId;
    this.quoteService.getRelationalName(dealerId).subscribe((response) => {
      this.dealers = response.data;
      if (this.dealers.length === 1) {
        if (this.dealers[0].dealerBranches.length == 1) {
          this.form.controls[dealerBranchControlName].setValue(this.dealers[0].dealerBranches[0])
        } else {
          this.dealerBranches = this.dealers[0].dealerBranches
        }
      } else {
        this.dealerBranches = this.dealers.find((i) => i.id == dealerId)?.dealerBranches ?? [];
      }
    })
  };

  onChangeDealer(): void { 
    const dealerId = this.form.controls[dealerIdControlName].value
    this.dealerBranches = []
    let filter: DealerBranch[] = this.dealers
      .filter((i: any) => i.id == dealerId)
      .map((i: Dealer) => i.dealerBranches)
      .flat()
      .filter((i: any) => (i.sofomMarshId || i.dtfsMarshId) && i.enabled);

    if (filter) {
      this.form.controls[dealerBranchControlName].setValidators([Validators.required]);
      this.form.controls[dealerBranchIdControlName].setValidators([Validators.required]);
      this.form.controls[dealerBranchIdControlName].setValue(filter[0].id);
      this.dealerBranches = filter;
      if(filter.length === 0 || filter === null || filter === undefined){
        this.form.controls[dealerBranchIdControlName].setValue(null);
        this.form.controls[dealerBranchControlName].setValue(null);
      }
      if(filter.length === 1){
        this.form.controls[dealerBranchControlName].setValue(filter[0]);
      }
    } else {
      this.form.controls[dealerBranchControlName].removeValidators([Validators.required]);
      this.form.controls[dealerBranchIdControlName].removeValidators([Validators.required]);
      this.form.controls[dealerBranchControlName].setValue(null);
      this.form.controls[dealerBranchIdControlName].setValue(null);
    }
    this.form.controls[dealerBranchControlName].updateValueAndValidity({onlySelf: true});
    this.form.controls[dealerBranchIdControlName].updateValueAndValidity({onlySelf: true});
  }
  

  setAccessoriesValues(accessoriesData: any[]) {
    const accessoriesArray = this.form.get('accessories') as FormArray;
    accessoriesArray.clear();

    accessoriesData.forEach((accessory) => {
      const accessoryFormGroup = this.fb.group({
        id: [accessory.id],
        price: [accessory.price],
        classification: [accessory.classification],
        year: [accessory.year],
        model: [accessory.model],
        quantity: [accessory.quantity],
      });
      accessoriesArray.push(accessoryFormGroup);
    });
  }

  update(isDownload?: boolean) {
    // Obtener el valor del formulario y almacenarlo en formData
    const formData = this.form.getRawValue();

    if (isDownload) {
      formData.status = statusType.saved;
      this.form.controls['status'].setValue('SAVED');
    }
    this.quoteService.formData = formData;
    this.setValueInService();

    this.isLoading = true;
    const userId = this.authService.currentUserIds;


    if (userId !== this.form.controls['creationUser'].value) {
      if (isDownload) {
        this.downloadPdf();
      }
      return;
    }


    // Llamada al servicio para actualizar la cotización
    this.quoteService.updateQuote(this.form.controls['id'].value).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.showNotificationSuccess(
          'La cotización se ha guardado correctamente.'
        );

        if (isDownload) {
          this.downloadPdf();
        }
      },
      error: (error) => {
        console.error('Error al guardar la cotización:', error);
        this.showNotificationError(
          'Ha ocurrido un error al momento de guardar la cotización.'
        );
        this.isLoading = false;
      },
    });
}


  isEditingMode(): boolean {
    return this.deals.length > 0 && this.idQuote;
  }

  saveDraft() {
    this.isLoading = true;
    this.quoteService.saveDraft().subscribe({
      next: (response) => {
        this.isLoading = false;
        // this.setResponseOnForm(response.data);
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }

  downloadPdf() {
    this.isLoading = true;
    this.quoteService.downloadPdf(this.idQuote).subscribe({
      next: (blob: Blob) => {
        this.saveAsPdf(blob, `Cotizacion_${this.idQuote}`);
        this.isLoading = false;
        this.ngOnInit();
      },
      error: (blob: Blob) => {
        this.isLoading = false;
        this.showNotificationError(
          'Ha ocurrido un error no se pudo descargar pdf.'
        );
      },
    });
  }

  setValueInService() {
    this.setValueDownpaymentAndOpeningfee();
    this.quoteService.formData = this.form.getRawValue();
  }

  setValueDownpaymentAndOpeningfee() {
    let deals = this.form.controls['standardQuoteDeals'].value;
    let newDeals = deals.map((i: any) => {
      return {
        ...i,
        financialSetup: {
          ...i.financialSetup,
          downPayment: i.downPayment,
          downPaymentTax: i.downPaymentTax,
          downPaymentTaxType: i.downPaymentTaxType,
          openingFee: i.openingFee,
          openingFeeTax: i.openingFeeTax,
          openingFeeTaxType: i.openingFeeTaxType,
          termId: i.financialCondition.id,
        },
      };
    });
    this.form.controls['standardQuoteDeals'].patchValue(newDeals);
    this.modalService.closeModal();
  }

  cancel() {
    this.router.navigate(['/quote/standard-quote']);
  }

  ngOnDestroy() {
    this._quote = null;
  }

  setQuantity(quantity: any) {
    this.form.controls['quantity'].setValue(quantity);
    this.quoteService.updatedFormData.next(this.form.getRawValue());
  }

  status() {
    const form = this.form;
    for (const controlName of Object.keys(form.controls)) {
      const control = form.get(controlName);

      if (control && control.invalid) {
      }
    }
  }

  updateDeal(deals: any) {
    this.form.controls['standardQuoteDeals'].reset();
    this.form.controls['standardQuoteDeals'].setValue(deals);
    this.deals = deals;
    this.quoteService.formData = this.form.getRawValue();
    // this.update()
  }


  openModal() {
    this.quoteService.updateForm(this.form.getRawValue());
    if (this.isEditingMode()) {
      this.modalService.component = this.modalTemplate;
      this.modalService.openModal();
    }
  }

  onInputFocus() {
    this.openModal();
  }

  openRequestedModal() {
    this.openModal();
  }

  cancelChanges() {
    if (!this.quoteService.formData) return;
    this.form.patchValue(this.quoteService.formData);
    this.modalService.closeModal();
  }

  deleteAllDeals() {
    this.modalService.closeModal();
    this.isLoading = true;
    this.quoteService.deleteAllDeals(this.idQuote).subscribe({
      next: (response) => {
        this.form.controls['standardQuoteDeals'].setValue([]);
        this.setValueInService();
        this.showNotificationSuccess(
          'Las propuestas se han eliminado correctamente.'
        );
        this.isLoading = false;
      },
      error: (error) => {
        this.showNotificationError(
          'Ha ocurrido un error al momento de eliminar las porpuestas.'
        );
        this.isLoading = false;
      },
    });
  }

  updateForm(value: any) {
    this.form.patchValue(value);
  }

}
