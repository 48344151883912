import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiService } from './api.base.service';
import { PermissionService } from './permission-manager.service';
import { SubBrandService } from '@app/services/subbrand.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [ApiService, PermissionService, SubBrandService],
  exports: [HttpClientModule],
})
export class CommonServicesModule {}
