import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {quoteDto} from '../../model/standard-quote.model';
import {StandardQuoteService} from '../../standard-quote-service.service';
import {AuthService} from "../../../../../common/auth.service";

@Component({
  selector: 'app-financial-service-view',
  templateUrl: './financial-service-view.component.html',
  styleUrls: ['./financial-service-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialServiceViewComponent implements OnInit {
  private quoteService = inject(StandardQuoteService);
  financialService: quoteDto | null = this.quoteService.formData;
  dealerId: number;
  dealerBranchName: string | undefined;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    const dealers = this.authService.currentDealers;
    if (this.financialService?.dealerId) {
      const dealer = dealers.find((i: any) => i.id == this.financialService?.dealerId);
      this.dealerId = dealer?.id;
      if(dealer?.dealerBranches?.length === 1){
        this.dealerBranchName = dealer?.dealerBranches[0].name
      }if (dealer?.dealerBranches?.length > 1 && this.financialService?.wholeSaleId) {
        const branch = dealer?.dealerBranches.find((i: any) => i.id == this.financialService?.wholeSaleId);
        if (branch) {
          this.dealerBranchName = branch.name;
        } else {
          console.warn("Branch not found for wholeSaleId:", this.financialService?.wholeSaleId);
        }
      }
    }
  }

  getCompanyName(name: string) {
    if (name == '') {
      // getCompanyName(
    } else if (name == '') {
    }
  }
}
